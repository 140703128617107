import React, { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';

import theme, {bp, color} from 'settings/theme';

type Props = {};

// Component
const HomeHero: React.FC<Props> = (props) => {

  const [scrollPosition, setScrollPosition] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    
    const handleScroll = () => {

      if(!ref.current){ return; }

      setScrollPosition(window.pageYOffset /2);
      
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);

  }, []);

  return (
    <StyledHomeHero ref={ref} className="homeHero">
      
      <div className="homeHero-image" style={{
        backgroundSize: 'cover',
        transform: `translateY(${scrollPosition}px)` 
      }}>
        
      </div>

    </StyledHomeHero>
  );
};

export default HomeHero;


const StyledHomeHero = styled.div<{disabled: boolean, imageSmallUrl: string, imageLargeUrl: string}>`

  position: relative;
  z-index: 0;

  .homeHero-image{
    position: relative;
    overflow: hidden;
    width: 100%;
    height: calc(100vh - 3.5rem);
    height: calc(100svh - 3.5rem);
    background-position: center;
    background-image: url('/hj/images/homepage-hero-tennis.jpg');
  }


  @media (min-width: ${bp(3)}) {
   
    .homeHero-episodeTitle{
      margin-bottom: 1rem;
      font-size: 1rem;
      font-weight: 600;
    }

    .homeHero-main{

      width: 100%;
      aspect-ratio: 4 / 1;
      max-height: 20rem;
      border-radius: 1rem;
      background-color: ${color.offGray(15)};
      overflow: none;

      .homeHero-image{
        background-image: url(${props => props.imageLargeUrl});
      }

    }

  }

`;
