import React, { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';

import theme, {bp, color} from 'settings/theme';

type Props = {
  episodeTitle: string,
  title: string,
  subTitle: string,
  imageSmallUrl: string,
  imageLargeUrl: string,
  productUrl?: string,
  blurred?: boolean
};

// Component
const HomeProduct: React.FC<Props> = (props) => {

  const [scrollPosition, setScrollPosition] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    
    const handleScroll = () => {

      if(!ref.current){ return; }

      // get viewport height
      const vh = window.innerHeight;

      // get element top position
      const elY = ref.current?.getBoundingClientRect().top;

      // Calculate elY as a percentage of the viewport height
      let elYPercent = ((elY / vh) * 80) + 10;
      elYPercent = Math.min(Math.max(elYPercent, 0), 100);

      setScrollPosition(elYPercent);
      
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <StyledHomeProduct ref={ref} imageSmallUrl={props.imageSmallUrl} imageLargeUrl={props.imageLargeUrl} disabled={!props.productUrl} className="homeProduct">
      
      <div className="homeProduct-episodeTitle">
        {props.episodeTitle}
      </div>

      <Link href={props.productUrl ?? '/'} className="homeProduct-link">
        <div className="homeProduct-main">
          <div className="homeProduct-title">{props.title}</div>
          <div className="homeProduct-subTitle">{props.subTitle}</div>
          <div className="homeProduct-image" style={{
            backgroundSize: 'cover',
            backgroundPosition: `center ${scrollPosition}%`
          }}></div>
        </div>
      </Link>

    </StyledHomeProduct>
  );
};

export default HomeProduct;


const StyledHomeProduct = styled.div<{disabled: boolean, imageSmallUrl: string, imageLargeUrl: string}>`

  .homeProduct-episodeTitle{
    margin-bottom: 0.5rem;
    color: ${color.gray(10)};
    font-weight: 600;
  }

  .homeProduct-main{

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    aspect-ratio: 1 / 1.125;
    border-radius: 1rem;
    background-color: ${color.offGray(15)};
    overflow: hidden;
    transition: all 0.25s ease-in-out;

    &:hover{
      filter: brightness(1.25);
    }

    .homeProduct-title{
      z-index: 2;
      padding: 1.25rem 8rem 0 1.25rem;
      font-size: 2.25rem;
      font-weight: 850;
    }

    .homeProduct-subTitle{
      z-index: 2;
      padding: 0 8rem 1.25rem 1.25rem;
      font-size: 1.5rem;
      font-weight: 850;
    }

    .homeProduct-image{
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url(${props => props.imageSmallUrl});
      
      filter: saturate(0) brightness(50%);

    }

  }

  cursor: not-allowed;

  ${props => props.disabled && css`

    .homeProduct-link{
      pointer-events: none;
    }

    .homeProduct-main{

      cursor: not-allowed;

      .homeProduct-image{
        filter: saturate(0) brightness(50%) blur(8px);
      }

    }  
      
  `}


  @media (min-width: ${bp(3)}) {
   
    .homeProduct-episodeTitle{
      margin-bottom: 1rem;
      font-size: 1rem;
      font-weight: 600;
    }

    .homeProduct-main{

      width: 100%;
      aspect-ratio: 4 / 1;
      max-height: 20rem;
      border-radius: 1rem;
      background-color: ${color.offGray(15)};
      overflow: none;

      .homeProduct-image{
        background-image: url(${props => props.imageLargeUrl});
      }

    }

  }

`;
