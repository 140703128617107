import { ReactElement, useEffect , useState } from 'react';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';
import { createServerSideHelpers } from '@trpc/react-query/server';
import { DehydratedState } from '@tanstack/react-query';
import { createContext } from 'server/context';

import superJson from 'utils/superJson';
import { appRouter } from 'server/routers/_app';
import getTemplateData from 'utils/getTemplateData';

import MainLayout from 'components/layout/mainLayout';
import HomeProduct from 'components/homepage/homeProduct';
import HomeHero from 'components/homepage/homeHero';
import MaxWidthWrapper from 'components/layout/maxWidthWrapper';

import useLayout from 'providers/layout/layoutProvider';
import theme, { bp, color } from 'settings/theme';


type Props = {
  templateData: TemplateData,
  trpcState: DehydratedState
};

const HomePage: NextPageExtended<Props> = (props) => {
  
  // Handle back to top click
  const handleTopClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>

      <Head>
        <title>HighJinx</title>
      </Head>

      <StyledHomePage className="homePage">

        {/* Hero Section */}
        <HomeHero />

        {/* <div className="homepage-hero">
          <div className="homepage-hero-image" style={{ transform: `translateY(${scrollPosition * 0.7}px)` }}>
            <Image src="/hj/images/homepage-hero-tennis.jpg" width={1900} height={1080} style={{width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center'}} alt="Tennis Ball" />
          </div>
        </div> */}

        {/* Products Section */}
        <div className="homepage-products">
          <div className="homepage-product-inner">

            <div className="homepage-product">
              <HomeProduct 
                episodeTitle="Episode 1" 
                title="The Anatomy Of A Rug" 
                subTitle="Featuring SCUM" 
                imageSmallUrl="/hj/images/homepage-products/product-1-small.jpg" 
                imageLargeUrl="/hj/images/homepage-products/product-1-large.jpg"
                productUrl="/shop/anatomy-of-a-rug" 
              />
            </div>

            <div className="homepage-product">
              <HomeProduct 
                episodeTitle="Episode 2" 
                title="It's OKAY to not be OKAY" 
                subTitle="Featuring TBD" 
                imageSmallUrl="/hj/images/homepage-products/product-2-small.jpg" 
                imageLargeUrl="/hj/images/homepage-products/product-2-large.jpg"
                blurred={true} 
              />
            </div>

            <div className="homepage-product">
              <HomeProduct 
                episodeTitle="Episode 3" 
                title="Burned Alive" 
                subTitle="Featuring TBD" 
                imageSmallUrl="/hj/images/homepage-products/product-3-small.jpg" 
                imageLargeUrl="/hj/images/homepage-products/product-3-large.jpg"
                blurred={true} 
              />
            </div>

            <div className="homepage-product">
              <HomeProduct 
                episodeTitle="Episode 4" 
                title="Ice Ice Baby" 
                subTitle="Featuring TBD" 
                imageSmallUrl="/hj/images/homepage-products/product-4-small.jpg" 
                imageLargeUrl="/hj/images/homepage-products/product-4-large.jpg"
                blurred={true} 
              />
            </div>

          </div>
          <div className="homepage-products-top">
            <a onClick={handleTopClick}>
              <span>Back to top</span>
              <span><img src="/images/icons/icon-arrow-up-black.svg" alt="arrow" /></span>
            </a>
          </div>
        </div>

      </StyledHomePage>

    </>
  );

}

export const getStaticProps = async ({ params }) => {

  const ssg = createServerSideHelpers({
    router: appRouter,
    ctx: await createContext(),
    transformer: superJson
  });


  const results = await Promise.all([
    getTemplateData()
  ]);

  const templateData = results[0];

  return {
    props: {
      templateData,
      trpcState: ssg.dehydrate(),
    },
    revalidate: 1
  };

};


HomePage.getLayout = (page: ReactElement) => {
  return (
    <MainLayout footerTheme="light" footerBackgroundColor={color.green(45)} templateData={page.props.templateData}>
      {page}
    </MainLayout>
  )
}

export default HomePage;

const StyledHomePage = styled.div`

  .homepage-hero{

    position: relative;
    overflow: hidden;
    width: 100%;
    height: calc(100vh - 3.5rem);
    height: calc(100svh - 3.5rem);

    .homepage-hero-image{
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

  }

  .homepage-products{

    position: relative;
    padding: 2rem 1rem;
    background-color: ${color.green(45)};

    .homepage-products-top{

      margin-top: 1rem;
      margin-bottom: 1rem;
      text-align: right;

      a{

        display: inline-flex;
        gap: 0.5rem;
        align-items: center;
        height: 2rem;
        margin-right: 0.5rem;
        color: black;
        background-color: transparent;

        img{
          position: relative;
          top: 0.125rem;
          width: 1rem;
          height: 1rem;
        }

      }

    }

  }

  .homepage-product-inner{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 75rem;
    margin: 0 auto;
  }

  @media (min-width: ${bp(3)}) {

    .homepage-product-inner{
      gap: 2rem;
    }

  }

`;